
import { defineComponent } from "vue";
import { LocationQueryValue } from "vue-router";

export default defineComponent({
  name: "Home",
  components: {},
  data() {
    return {
      videoId: "" as string | LocationQueryValue[],
      name: "Hallo",
      videoSrc: 'https://affectivity-storage.lwmicro.com/output/yer_pv/5bb8ff28b36dea273728538040374d3423d710fc.mp4',
      shareData: {
        title: "Yer",
        text: "Ben jij klaar om je ambities in beweging te brengen? Genereer hier je persoonlijke  Ambitions in Motion video",
        url: window.location.href,
      },
    };
  },
  created() {
    if (this.$route.query.videoID) {
      this.videoId = this.$route.query.videoID;
    }

    if (this.$route.query.name) {
      this.name = this.$route.query.name as string;
      this.name = this.name.charAt(0).toUpperCase() + this.name.slice(1);
    }

    this.renderVideo();
  },
  methods: {
    renderVideo() {
      if (this.videoId) {
        this.videoSrc = `https://affectivity-storage.lwmicro.com/output/yer_pv/${this.videoId}.mp4`;
      }
    },
    async handleShare(channel: string) {
      switch (channel) {
        case "general":
          try {
            await navigator.share(this.shareData);
          } catch (err) {
            console.log(err);
          }
          break;

        case "twitter":
          const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            `${this.shareData.text}`
          )}&url=${encodeURIComponent(`${this.shareData.url}`)}`;
          window.open(
            url,
            "twitter",
            `width=550,height=450,scrollbars=yes,toolbar=no,location=yes`
          );
          break;

        case "facebook":
          const fb = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            this.shareData.url
          )}`;
          window.open(
            fb,
            "facebook",
            `width=550, height=450, scrollbars=yes, toolbar=no, location=yes`
          );
          break;

        case "whatsapp":
          const waUrl = `https://wa.me/?text=${encodeURIComponent(
            `${this.shareData.text} ${this.shareData.url}`
          )}`;
          window.open(waUrl);
          break;

        case "download":
          let link = document.createElement("a");
          link.href = this.videoSrc;
          link.download = "Yer - Personal video.mp4";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          break;
      }
    },
  },
});
